import React, { Component } from "react";
import { Modal, Button, Label } from "semantic-ui-react";
import ReactTable from "react-table";
import { laravelService } from "../../utils/laravelService";
import { getStageName } from "../../utils/Utils";

export default class RemissionRodillosModal extends Component {
  state = {
    loading: false,
    rodillos: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          await laravelService
            .get(`reports/pedido-rodillos/${this.props.data.pedido_id}`)
            .then((res) => {
              this.setState({
                rodillos: res.data,
                loading: false,
              });
            });
        }
      );
    }
  }

  render() {
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        {this.props.data.total_rodillos && (
          <>
            <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
              OT #{this.props.data.pedido_id} | Partida #
              {this.props.data.partida_id}
              <Button
                circular
                size="mini"
                basic
                icon="close"
                floated="right"
                onClick={this.props.onClose}
              />
            </Modal.Header>
            <Modal.Content>
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                Rodillos en remisión: {this.props.data.rodillos_remission_total}
                /{this.props.data.total_rodillos}{" "}
                {this.props.data.rodillos_remission_total ===
                this.props.data.total_rodillos ? (
                  <Label basic color="green">
                    Listo para remisión
                  </Label>
                ) : (
                  <Label basic color="red">
                    Faltan rodillos
                  </Label>
                )}
              </span>
              <ReactTable
                loading={this.state.loading}
                columns={[
                  {
                    Header: "Rodillo",
                    id: "id",
                    accessor: (d) => "#" + d.id,
                  },
                  {
                    Header: "Fecha Creación",
                    id: "fecha_creado",
                    accessor: (d) => d.fecha_creado,
                  },
                  {
                    Header: "Fecha Terminado",
                    id: "fecha_terminado",
                    accessor: (d) =>
                      d.fecha_terminado ? d.fecha_terminado : "---",
                  },
                  {
                    Header: "Etapa",
                    id: "stage",
                    accessor: (d) => (
                      <Label basic color={d.stage === 13 ? "green" : "red"}>
                        {getStageName(d.stage)}
                      </Label>
                    ),
                  },
                ]}
                defaultPageSize={10}
                pageSize={10}
                manual
                data={this.state.rodillos}
                style={{ textAlign: "center", marginTop: 10 }}
              />
            </Modal.Content>
          </>
        )}
      </Modal>
    );
  }
}
