import Feathers from "../redux/FeathersRedux";
import Config from "../config/index";

export const exportToXls = async (data = [], type = "", filters = {}) => {
  console.log("data", data, filters);
  let xls = await Feathers.service("xls").create({
    data,
    type,
    filters
  });

  let pdfURL = `${
    Config.apiUrl
  }/download_xls?file=${xls}&token=${localStorage.getItem("bingham-jwt")}`;
  window.open(pdfURL, "_blank");
  //window.location.href = `${Config.apiUrl}download_xls?file=${xls}&token=${localStorage.getItem('bingham-jwt')}`
};

export const exportToPdf = async (filename) => {
  //window.location.href = `${Config.apiUrl}/download_pdf?file=${filename}`
  window.open(
    `${
      Config.apiUrl
    }/download_pdf?file=${filename}&token=${localStorage.getItem(
      "bingham-jwt"
    )}`,
    "_blank"
  );
};

String.prototype.capitalizeWords = function() {
  let words = this.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

export const getStageName = (id) => {
    switch (id) {
      case 0:
        return "Incidencia";
      case 1:
        return "Desviste";
      case 2:
        break;
      case 3:
        return "Molino";
      case 4:
        return "Vulcanizado";
      case 5:
        return "Desmonte";
      case 6:
        return "Desbaste";
      case 7:
        return "Rectificado";
      case 8:
        return "Corte";
      case 9:
        return "Contrucción";
      case 10:
        return "Finalizado";
      case 11:
        return "Pend. Vulcanziado";
      case 12:
        return "Control Calidad";
      case 13:
        return "Remisión";
      case null:
        return "SIN SEGUIMIENTO";
      default:
        break;
    }
  };
