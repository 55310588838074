import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Grid, Form, Label, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import swal from "sweetalert2";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import { exportToXls } from "../../utils/Utils";
import TasksFilter from "../../components/FIlters/TasksFilter";
import Config from "../../config";
import { laravelService } from "../../utils/laravelService";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class RemissionCreate extends Component {
  constructor() {
    super();

    this.state = {
      orders: [],
      total: 0,
      page: 0,
      columna: "",
      sortDirection: true,
      loading: false,
      currentRow: {},
      isInfoOpened: false,
      isEditOpened: false,
      pageSize: 25,
      filters: ["pedido", "partida", "costumer", "material", "entrega"],
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
        material_name: null,
      },
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      cliente: null,
      material: null,
      clienteLoading: null,
      materialLoading: null,
      clienteResults: null,
      materialResults: null,
      mancheta_id: null,
      results: null,
      client: "",
      $sort: {
        $fechaAltaSortDirection: null,
        $fechaEntregaSortDirection: null,
        $saeSortDirection: null,
      },
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.getStatusFilterOptions();
  }

  getStatusFilterOptions = async (_) => {
    let status_pedido = await Feathers.service("status_pedido").find({
      query: {
        $limit: -1,
        activo: 1,
      },
    });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        status_options: status_pedido.map((v) => {
          return {
            key: v.id,
            text: v.nombre,
            value: v.id,
          };
        }),
      },
    });
  };

  fetchData = async (state = {}) => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        let query = {};

        if (this.state.pageSize) {
          query["page_size"] = this.state.pageSize;
        }

        if (this.state.columna && this.state.sortDirection !== null) {
          query["sort_column"] = this.state.columna;
          query["sort_direction"] = this.state.sortDirection ? "ASC" : "DESC";
        }

        if (this.state.filterValues.pedidoValue) {
          query["pedido_id"] = this.state.filterValues.pedidoValue;
        }

        if (this.state.filterValues.partidaValue) {
          query["partida_id"] = this.state.filterValues.partidaValue;
        }

        if (this.state.filterOptions.cliente_id) {
          query["cliente_id"] = this.state.filterOptions.cliente_id;
        }

        if (this.state.filterOptions.material_id) {
          query["material_id"] = this.state.filterOptions.material_id;
        }

        if (this.state.filterValues.fechaEntrega) {
          query["fecha_entrega"] = moment(
            this.state.filterValues.fechaEntrega
          ).format("YYYY-MM-DD HH:mm:ss");
        }

        await laravelService
          .get(`reports/remissions-emitted?page=${this.state.page + 1}`, {
            params: query,
          })
          .then((res) => {
            this.setState({
              orders: res.data.data,
              total: res.data.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize,
            });
          });
      }
    );
  };

  showRemission = async (order) => {
    try {
      // window.open('', '_blank').location.href = `https://facturacion.bingham.acsyt.com/storage/orders/${order.id}/remission.pdf`;
      window.open(
        "",
        "_blank"
      ).location.href = `${Config.laravelUrl}/storage/orders/${order.pedido_id}/remission.pdf`;
    } catch (error) {
      console.log(error, "averrr");
      swal({
        title: "Ocurrió un error",
        type: "error",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        reverseButtons: true,
      });
    }
  };

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  onPartidaChange = (partida) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          partidaValue: partida,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onChangePedido = (pedido) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          pedidoValue: pedido,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          fechaEntrega: dateFormat,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  };

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  };

  handleCleanButton = async () => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState(
      {
        filterValues: {
          cliente_id: null,
          material_id: null,
          ref_sae: null,
          pedido_id: null,
          partida_id: null,
          material_name: null,
        },
        filterOptions: {
          rodilloValue: null,
          partidaValue: null,
          pedidoValue: null,
          fechaEntrega: null,
        },
        material: null,
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <TasksFilter
                  loading={this.state.loading}
                  clienteLoading={this.state.clienteLoading}
                  filters={this.state.filters}
                  costumer={this.state.cliente || ""}
                  costumers={this.state.clienteResults}
                  onSelectCostumer={this.handleClienteResultSelect}
                  onSearchCostumerChange={this.handleClienteSearchChange}
                  costumerResultRenderer={renderClientSearchResults}
                  partidaValue={this.state.filterValues.partidaValue || ""}
                  onChangePartida={this.onPartidaChange}
                  fechaEntrega={this.state.filterValues.fechaEntrega || ""}
                  onDateChange={this.onDateChange}
                  materialLoading={this.state.materialLoading}
                  materials={this.state.materialResults}
                  material={this.state.material ? this.state.material : ""}
                  handleMaterialResultSelect={this.handleMaterialResultSelect}
                  handleMaterialSearchChange={this.handleMaterialSearchChange}
                  renderMaterialSearchResults={renderMaterialSearchResults}
                  pedidoValue={this.state.filterValues.pedidoValue || ""}
                  onPedidoChange={this.onChangePedido}
                />
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      window.open(
                        "",
                        "_blank"
                      ).location.href = `${Config.laravelUrl}/api/excel/remissions-emitted`;
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            {
              Header: "OT",
              id: "pedido_id",
              maxWidth: 90,
              accessor: (d) => d.pedido_id,
            },
            {
              Header: "Partida",
              id: "partida_id",
              maxWidth: 90,
              accessor: (d) => d.partida_id,
            },
            {
              id: "cliente_nombre",
              Header: "Cliente",
              accessor: "cliente_nombre",
            },
            {
              Header: "Fecha Alta",
              id: "fecha_alta",
              accessor: (d) => new Date(d.fecha_alta).toLocaleDateString(),
            },
            {
              Header: "Fecha Entrega",
              id: "fecha_entrega",
              accessor: (d) => new Date(d.fecha_entrega).toLocaleDateString(),
            },
            {
              Header: "Material",
              id: "material_nombre",
              maxWidth: 250,
              accessor: (d) => d.material_nombre,
            },
            {
              Header: "Cantidad",
              id: "cantidad",
              maxWidth: 100,
              accessor: (d) => d.cantidad,
            },
            {
              Header: "DN",
              id: "dn",
              maxWidth: 80,
              accessor: (d) => d.dn,
            },
            {
              Header: "DE",
              id: "de",
              maxWidth: 80,
              accessor: (d) => d.de,
            },
            {
              Header: "LC",
              id: "lc",
              maxWidth: 80,
              accessor: (d) => d.lc,
            },
            {
              Header: "LT",
              id: "lt",
              maxWidth: 80,
              accessor: (d) => d.lt,
            },
            {
              Header: "Kilos",
              id: "kilos",
              maxWidth: 80,
              accessor: (d) => d.kilos,
            },
            {
              Header: "Dureza",
              id: "dureza_valor",
              accessor: (d) => d.dureza_valor,
              maxWidth: 80,
            },
            {
              Header: "Acciones",
              Cell: (row) => (
                <div>
                  <Popup
                    content="Ver Remisión"
                    trigger={
                      <Button
                        size="mini"
                        compact
                        basic
                        color="orange"
                        icon="file pdf outline"
                        onClick={() => this.showRemission(row.original)}
                      />
                    }
                  />
                </div>
              ),
            },
          ]}
          manual
          data={this.state.orders}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          page={this.state.page}
          pageIndex={this.state.page}
          onFetchData={this.fetchData}
          defaultPageSize={this.state.pageSize}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (newSorted[0].id == this.state.columna) {
              direction = !this.state.sortDirection;
            } else {
              console.log(this.state.sortDirection);
              direction = !this.state.sortDirection;
            }

            this.setState(
              {
                columna: newSorted[0].id,
                sortDirection: this.state.sortDirection ? false : true,
              },
              () => {
                this.fetchData();
              }
            );
          }}
        />
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(RemissionCreate);
