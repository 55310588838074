import "moment/locale/es";
import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Form,
  Search,
  Label,
  Popup,
  Header
} from "semantic-ui-react";
import { connect } from "react-redux";
import swal from "sweetalert2";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import PropTypes from "prop-types";
import Feathers from "../redux/FeathersRedux";
import OrdersInfoModal from "../components/Orders/OrdersInfoModal";
import TasksFilter from "../components/FIlters/TasksFilter";
import Config from "../config";
import { exportToXls } from "../utils/Utils";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class QuotationsContainer extends Component {
  constructor() {
    super();

    this.state = {
      orders: [],
      total: 0,
      page: 0,
      loading: false,
      currentRow: {},
      isInfoOpened: false,
      isEditOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
        status_id: null,
        status_options: [],
        fecha_alta: "",
        fecha_entrega: "",
      },
      filter: {
        initDay: "",
        lastDay: "",
        cliente_id: null,
        rodilloValue: "",
        partidaValue: "",
        pedidoValue: "",
        statusValue: "",
      },
      mancheta_id: null,
      results: null,
      client: "",
      $sort: {
        $fechaAltaSortDirection: null,
        $fechaEntregaSortDirection: null,
        $saeSortDirection: null,
      },
      columna: "",
      sortDirection: false,
      shouldExport: false,
      filters: ["pedido", "partida", "costumer", "entrega"],
    };

    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
  }

  componentDidMount() {
    this.getStatusFilterOptions();
  }

  getStatusFilterOptions = async (_) => {
    let status_pedido = await Feathers.service("status_pedido").find({
      query: {
        $limit: -1,
        activo: 1,
      },
    });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        status_options: status_pedido.map((v) => {
          return {
            key: v.id,
            text: v.nombre,
            value: v.id,
          };
        }),
      },
    });
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Entrega":
        array.data.sort((a, b) => {
          const nameA = a.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      // ------------------- bug: no se ordena
      case "1er Producto":
        array.data.sort((a, b) => {
          let nameA = a.producto
            ? a.partidas.producto.nombre.toUpperCase()
            : "";
          let nameB = b.producto
            ? a.partidas.producto.nombre.toUpperCase()
            : "";
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          let A = a.partidas.cantidad;
          let B = b.partidas.cantidad;
          return A - B;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.partidas.dureza ? a.partidas.dureza.valor : 0;
          let B = b.partidas.dureza ? b.partidas.dureza.valor : 0;
          return A - B;
        });
        break;
      case "P. Mancheta":
        array.data.sort((a, b) => {
          let nameA = a.partidas.manchetas
            ? a.partidas.manchetas.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.partidas.manchetas
            ? b.partidas.manchetas.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Status":
        array.data.sort((a, b) => {
          let A = a.status.id;
          let B = b.status.id;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      status_id: {
        $in: [1],
      },
      $sort: {
        id: -1,
      },
    };

    if (this.state.filter.pedidoValue) {
      query["id"] = this.state.filter.pedidoValue;
    }

    if (this.state.filter.cliente_id) {
      query["cliente_id"] = this.state.filter.cliente_id;
    }

    if (this.state.filter.partidaValue) {
      let partida = await Feathers.service("partida").get(
        this.state.filter.partidaValue
      );
      if (partida) {
        query["id"] = partida.pedido_id;
      }
    }

    if (this.state.filter.cliente_id) {
      query["cliente_id"] = this.state.filter.cliente_id;
    }

    if (this.state.filterOptions.fecha_entrega) {
      console.log("fe", this.state.filterOptions.fecha_entrega);
      query["fecha_entrega"] = moment(
        this.state.filterOptions.fecha_entrega
      ).format("YYYY-MM-DD");
    }

    this.setState({ loading: true }, () => {
      Feathers.service("pedido")
        .find({
          query,
        })
        .then((res) => {
          console.log("res", res);
          res = this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              orders: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_orders");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  handleResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingSearch: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSearch: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    await this.setState({
      client: "",
      results: null,
      filterOptions: {
        ...this.state.filterOptions,
        id: null,
        status_id: null,
        fecha_entrega: "",
        fecha_alta: "",
      },
    });

    this.fetchData(this.state);
  };

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });

  openInfoModal(row) {
    this.setState({
      isInfoOpened: true,
      currentRow: row.original,
    });
  }

  closeInfoModal() {
    this.setState(
      {
        isInfoOpened: false,
      },
      () => {
        this.fetchData({
          page: this.state.page,
          pageSize: this.state.pageSize,
        });
      }
    );
  }

  handleFilterSubmit = async () => {
    await this.fetchData(this.state);
  };

  handleClientResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    await this.setState(
      {
        filter: {
          ...this.state.filter,
          cliente_id: result.id,
        },
      },
      () => console.log("state stage", this.state)
    );

    this.fetchData(this.state);
  };

  handleClientSearchChange = async (e, { value }) => {
    await this.setState({ clienteLoading: true, cliente: value });
    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then(
          (res) => {
            this.setState({
              clienteLoading: false,
              clienteResults: res.data,
            });
          },
          () => {
            console.log("state", this.state);
          }
        );
    }, 500);
  };

  resetClienteComponent = () =>
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });

  changeInitDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        initDay: date,
      },
    });
  };

  changeLastDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        lastDay: date,
      },
    });
  };

  onPedidoChange = (pedido) => {
    this.setState({
      filter: {
        ...this.state.filter,
        pedidoValue: pedido,
      },
    });
  };

  onPartidaChange = (partida) => {
    this.setState({
      filter: {
        ...this.state.filter,
        partidaValue: partida,
      },
    });
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          fecha_entrega: dateFormat,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Header as="h2" icon="shop" content="Cotizaciones" />
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <TasksFilter
                  loading={this.state.loading}
                  filters={this.state.filters}
                  partidaValue={this.state.filter.partidaValue || ""}
                  onChangePartida={this.onPartidaChange}
                  pedidoValue={this.state.filter.pedidoValue || ""}
                  onPedidoChange={this.onPedidoChange}
                  costumer={this.state.filter.cliente}
                  costumers={this.state.clienteResults}
                  onSelectCostumer={this.handleClientResultSelect}
                  onSearchCostumerChange={this.handleClientSearchChange}
                  costumerResultRenderer={renderClientSearchResults}
                  fechaEntrega={this.state.filterOptions.fecha_entrega || ""}
                  onDateChange={this.onDateChange}
                />
                <Form.Field>
                  <label>Filtrar</label>
                  <Button
                    basic
                    circular
                    icon="filter"
                    onClick={this.handleFilterSubmit}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      let urlExcel = `${Config.laravelUrl}/api/excel/quotations?`;

                      if (this.state.filter.pedidoValue) {
                        urlExcel += `filter[pedido_id]=${this.state.filter.pedidoValue}&`;
                      }

                      if (this.state.filter.cliente_id) {
                        urlExcel += `filter[cliente_id]=${this.state.filter.cliente_id}&`;
                      }

                      if (this.state.filter.partidaValue) {
                        urlExcel += `filter[partida_id]=${this.state.filter.partidaValue}&`;
                      }

                      if (this.state.filterOptions.fecha_entrega) {
                        urlExcel += `filter[fecha_entrega]=${moment(
                          this.state.filterOptions.fecha_entrega
                        ).format("YYYY-MM-DD HH:mm:ss")}&`;
                      }

                      console.log(urlExcel, "url");

                      window.open("", "_blank").location.href = urlExcel;
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            {
              Header: "OT",
              id: "id",
              maxWidth: 75,
              accessor: (d) => d.id,
            },
            {
              Header: "Partida",
              headerStyle: { fontSize: 16 },
              maxWidth: 95,
              id: "partida_id",
              accessor: (d) => {
                if (d.partidas) return d.partidas.id;
                return "---";
              },
            },
            { Header: "Cliente", accessor: "cliente.nombre" },
            {
              Header: "Fecha Entrega",
              id: "fecha_entrega",
              maxWidth: 95,
              accessor: (d) => new Date(d.fecha_entrega).toLocaleDateString(),
            },
            {
              Header: "Material",
              id: "material",
              maxWidth: 100,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  if (d.partidas[0].material) {
                    return d.partidas[0].material.nombre;
                  } else {
                    return "---";
                  }
                } else if (d.partidas.material) {
                  return d.partidas.material.nombre;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "Dureza",
              id: "dureza",
              maxWidth: 80,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  if (d.partidas[0].dureza) {
                    return d.partidas[0].dureza.valor;
                  } else {
                    return "---";
                  }
                } else if (d.partidas.dureza) {
                  return d.partidas.dureza.valor;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "DN/Altura",
              id: "dn",
              maxWidth: 80,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  return d.partidas[0].mancheta
                    ? d.partidas[0].manchetas.altura_espec
                    : parseInt(d.partidas[0].dn);
                } else if (d.partidas) {
                  return d.partidas.mancheta
                    ? d.partidas.mancheta.altura_espec
                    : d.partidas.dn;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "DE/Espesor",
              id: "de",
              maxWidth: 80,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  return d.partidas[0].mancheta
                    ? d.partidas[0].manchetas.espesor_espec
                    : parseInt(d.partidas[0].de);
                } else if (d.partidas) {
                  return d.partidas.mancheta
                    ? d.partidas.mancheta.espesor_espec
                    : d.partidas.de;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "LC/Perímetro",
              id: "lc",
              maxWidth: 80,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  return d.partidas[0].mancheta
                    ? d.partidas[0].manchetas.perimetro_espec
                    : parseInt(d.partidas[0].lc);
                } else if (d.partidas) {
                  return d.partidas.mancheta
                    ? d.partidas.mancheta.perimetro_espec
                    : d.partidas.lc;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "LT",
              id: "lt",
              maxWidth: 80,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  return d.partidas[0].lt;
                } else if (d.partidas) {
                  return d.partidas.lt;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "Kilos",
              id: "kilos",
              maxWidth: 80,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  return d.partidas[0].kilos;
                } else if (d.partidas) {
                  return d.partidas.kilos;
                } else {
                  return "---";
                }
              },
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => (
                <div>
                  <Popup
                    content="Ver pedido"
                    trigger={
                      <Button
                        size="mini"
                        compact
                        basic
                        color="blue"
                        icon="eye"
                        onClick={() => this.openInfoModal(row)}
                      />
                    }
                  />
                  <Popup
                    content="Aprobar cotización"
                    trigger={
                      <Button
                        size="mini"
                        compact
                        basic
                        color="green"
                        icon="checkmark"
                        onClick={() => {
                          swal({
                            title: "¿Desea aprobar esta cotización?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sí",
                            cancelButtonText: "No",
                          }).then(async (result) => {
                            if (result.value) {
                              Feathers.service("pedido")
                                .patch(row.original.id, {
                                  status_id: 7,
                                })
                                .finally(() => {
                                  this.fetchData(this.state);
                                });
                            }
                          });
                        }}
                        // onClick={() => this.createInvoice(row.original)}
                      />
                    }
                  />
                </div>
              ),
            },
          ]}
          manual
          data={this.state.orders}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / 100)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
        <OrdersInfoModal
          isOpened={this.state.isInfoOpened}
          order={this.state.currentRow}
          onClose={this.closeInfoModal}
          callback={this.onValidateOrder}
          user={this.props.user.id}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(QuotationsContainer);
